import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ImgUrl, addDefaultSrc } from "../services";
import { Room, StarRate } from "@material-ui/icons";
import GInfo from "./gInfo";
import { makeStyles } from "@material-ui/core/styles";
import FeaturesCard from "../components/customFeaturesCard";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";
import PlaceholderImg from "../assets/images/placeholders/p-store.png"
const StoreBox = styled.div`
  position: relative;
  cursor: pointer;
  .rating {
    background: ${({ theme }) => theme.button};
    color: #fff;
    z-index: 2;
    padding: 3px 12px 3px 8px;
    border-radius: 20px;
    position: absolute;
    right: 10px;
    margin-top: 10px;
    .MuiSvgIcon-root {
      font-size: 20px;
      vertical-align: sub;
    }
  }
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border: 1px solid #e2e2e2;
  }

  h4 {
    color: #000;
    font-size: 17px;
    margin: 15px 0 5px;
    font-weight: 400;
  }
  p {
    color: #a2a2a2;
    font-size: 15px;
    margin: 0;
    font-family: LatoWeb;
    display: inline-flex;
    line-height: 20px;
    .MuiSvgIcon-root {
      font-size: 17px;
      vertical-align: top;
      color: #b2b2b2;
      padding-right: 5px;
    }
  }
  .closed {
    background: rgba(75, 144, 224, 0.6);
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: 1px;
    left: 1px;
    text-align: center;
    padding-top: 90px;
    width: 100%;
    z-index: 1;
    height: calc(200px - 90px);
    font-family: "LatoWebBold";
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    height: "max-content",
    borderRadius: 15,
    boxShadow: "none",
    cursor: "pointer",
    display: "flex",
    paddingLeft: "10px",
    height: "120px",
    margin: "0 auto",
    // backgroundColor: "#f9f9f9",
    // backgroundColor: "#f4f4f4",
    background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,244,230,1) 49%, rgba(255,237,214,1) 100%)",
    border: "2px solid #f3921d ",
    boxShadow: "4px 6px 5px 0px rgba(0,0,0,0.09)",
    "&:hover": {
      boxShadow: "rgb(0 0 0 / 20%) 4px 5px 12px",
    },
  },
  media: {
    // height: 0,
    // paddingTop: "60.25%", // 16:9
    width: "30%",
    background: "#f3f3f3",
    borderLeft: "1px solid #f3921d",
  },
  cardMedia: {
    // backgroundColor: "#000",


  },
  closed: {
    position: 'absolute',
    backgroundColor: 'orange',
    display: 'flex',
    marginLeft: '257px',
    color: 'white',
    textAlign: 'center',
    marginTop: ' 32px',
    padding: '6px',
    width: '62px',
    wordWrap: 'break-word'


  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },

  heading: {
    maxWidth: "300px",
    fontWeight: 400,
    fontSize: "1.2rem",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },

  cardContent: {
    padding: "11px 16px 2px 16px",
    width: "65%",
  },
  cardSubContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "19px",
  },
  rating: {
    backgroundColor: "#f3921d",
    padding: "3px 3px",
    width: '50px',
    paddingRight: "7px",
    borderRadius: "20px",
    color: "#f4f4f4",
  },
}));

function GFoodStores(props) {
  const classes = useStyles();
  const [storeData, setStoreData] = useState(props.data);


  return (



    <React.Fragment>

      {props.listView === true ? (
        <div className="checkDiv"
          onClick={() => {
            if (storeData.store_status === 0) {
              return;
            }
            props.onSelectStore(storeData);
          }}
        >
          <GInfo
            title={storeData?.title}
            subtitle2={storeData?.address}
            imgType="store"
            photo={storeData?.thumb_photo}
            rating={storeData.rating}
          />
        </div>
      ) : (
        <>
          <Card className={classes.root}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '70%', justifyContent: 'space-evenly' }}
              onClick={() => {
                if (storeData.store_status === 0) {
                  return;
                }
                if (storeData.comming_soon === 1) {
                  return;
                }
                props.onSelectStore(storeData);
              }}>
              <CardContent
                title={storeData.title}
                className={classes.cardContent}
                style={{ width: '100%' }}>
                <Typography className={classes.heading} variant="h4">
                  {storeData.title}
                </Typography>
              </CardContent>


              <Typography
                style={{ display: "flex", alignItems: "center" }}
                variant="body2"
                color="textSecondary"
                component="p"
              >
                <Room style={{ color: "#64b31b" }} />
                {storeData.address}
              </Typography>
              <span
                style={{ display: "flex", alignItems: "center" }}
                className={classes.rating}
                role="button"
                onClick={() => props.onShowReview(storeData)}
              >
                <StarRate />
                {storeData.rating}
              </span>

            </Box>
            <CardMedia

              className={classes.cardMedia}
              style={{
                background: `linear-gradient(rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.1)), 
                url("${storeData.thumb_photo === "" ? PlaceholderImg : ImgUrl("store") + "/" + storeData.thumb_photo}")`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                if (storeData.store_status === 0) {
                  return '';
                }
                if (storeData.coming_soon === 1) {
                  return '';
                }
                props.onSelectStore(storeData);
              }}
              alt={storeData.title}
              onError={(e) => addDefaultSrc(e, "store")}
              className={classes.media}
            // image={ImgUrl("store") + "/" + storeData.thumb_photo}
            />


            {storeData.comming_soon === 1 ? (
              <div className={classes.closed}> COMING SOON </div>
            ) : (
              storeData.store_status === 0 ? (
                <div className={classes.closed}>  CLOSED </div>
              ) : ''
            )}

          </Card>
        </>
      )}
    </React.Fragment>
  );
}

export default GFoodStores;
